<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col md="6"></b-col>
      <b-col md="6" class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button
          @click="openModal = !openModal"
          class="text-nowrap"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="text-nowrap">{{ $t('new_user') }}</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="users.users"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: searchQuery,
      }"
      styleClass="vgt-table"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'namePlayer'" class="text-capitalize overflow-x-autos">
          <!-- no-wrap -->
          <p class="whitespace-nowrap">{{`${props.row.name} ${props.row.lastname}`}}</p>
        </div>
        <div v-if="props.column.field == 'email'" >
          {{props.row.email}}
        </div>
        <div v-if="props.column.field == 'username'" >
          {{props.row.username}}
        </div>
        <div v-if="props.column.field == 'createdAt'" >
          {{ props.row.createdAt | formatDate}}
        </div>
        <!-- typeUser -->
        <div v-if="props.column.field == 'typeUser'" >
          <b-badge
            pill
            :variant="`light-danger`"
            class="text-capitalize"
          >
            {{ props.row.typeUser }}
          </b-badge>
        </div>
        <div v-if="props.column.field == 'actions'">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item @click="showProduct(props.row)">
              <feather-icon icon="BoxIcon" />
              <span class="align-middle ml-50">Products</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showGames(props.row)">
              <feather-icon icon="CpuIcon" />
              <span class="align-middle ml-50">Games</span>
            </b-dropdown-item> -->
            <b-dropdown-item @click="userChangePassword(props.row)">
              <feather-icon icon="KeyIcon" />
              <span class="align-middle ml-50">{{$t('labels.changePassword')}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-else-if="props.column.field == 'status'">
          <b-badge
            pill
            :variant="`light-${props.row.status ? 'success' : 'danger'}`"
            class="text-capitalize"
          >
            {{ props.row.status ? "Active" : "Inactive" }}
          </b-badge>
        </div>

      </template>
    </vue-good-table>
    <section style="padding: 30px 0 0px 0px;">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              first-number
              @change="getUsers"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" /> 
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

    <games ref="games"/>
    <products ref="products"/>
    <user-change-password ref="userChangePassword" />
    <b-modal no-close-on-backdrop v-model="openModal" hide-footer modal-class="modal-primary" centered :title="$t('new_user')" size="lg">
      <validation-observer ref="Form">
        <b-form class="p-2" @submit.prevent="addUser">
          <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required">
                  <b-form-group label="Nombres" class="my-1">
                    <b-form-input v-model="formData.name"/>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="lastname"
                  rules="required">
                  <b-form-group label="Apellidos" class="my-1">
                    <b-form-input v-model="formData.lastname"/>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">   
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required">
                  <b-form-group label="Username" class="my-1">
                    <b-form-input v-model="formData.username"/>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>


              <b-col md="6">                
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required">
                  <div  class="d-flex align-items-center">

                    <b-form-group class="my-1 w-100">
                      <label for="basic-password">Contraseña</label>
                      <b-input-group>
                        <b-form-input v-model="formData.password" :type="passwordFieldType"/>
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                      <div class="ml-1 pt-3">
                        <b-button
                          v-b-tooltip.hover.top="$t('generate_password')"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          @click="randomPass"
                        >
                          <feather-icon icon="RefreshCwIcon" />
                        </b-button>
                    </div>
                  </div>
               
                    
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-col>

                

              <b-col md="6">
                <validation-provider #default="{ errors }" name="customer" rules="required">
                  <b-form-group class="my-1">
                    <label for="customers">Tipo de usuario</label>
                    <v-select v-model="formData.typeUser" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeUserOptions" label="text" :reduce="(item) => item.value" placeholder="Tipo de usuario">
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider 
                v-if="typeUserLoged == 'Root' &&
                      (formData.typeUser == 'admin' || 
                      formData.typeUser == 'operator')
                      " 
                #default="{ errors }" 
                name="customer" 
                rules="required">

                  <b-form-group class="my-1">
                    <label for="customers">Cliente</label>
                    <v-select @search="searchCustomer" v-model="formData.customerId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="customerOptions" label="name" :reduce="(item) => item._id" placeholder="Select Customer">
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
          </b-row>

          <hr>
          

          <div class="d-flex align-items-center justify-content-between mt-2">
            <b-button
              :variant="'success'"
              class="mr-2"
              type="submit">
              <feather-icon :icon="'PlusIcon'" class="mr-50"/>
              <span class="align-middle">Agregar</span>
            </b-button>
        </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
  
</template>

<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,BForm,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import Games from './games.vue';
import products from './productsLock.vue';
import { required } from "@validations";
import UserChangePassword from "@/views/pagesIntegrations/users/userChangePassword";

export default {
  components: {
    UserChangePassword,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    Games,
    products,
    ValidationProvider,
    ValidationObserver,BForm,    BInputGroupPrepend,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    customerOptions: [],
    showPassword: false,
    required,
    openModal: false,
    loadingData: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    formData: {
      name: "",
      lastname: "",
      customerId: "",
      username: "",
      password: "",
      typeUser: "",
    },
    columns: [
      { label: "Name", field: "namePlayer" },
      { label: "Email", field: "email" },
      { label: "Username", field: "username" },
      { label: "Tipo de usuario", field: "typeUser" },
      { label: "status", field: "status" },
      { label: "createdAt", field: "createdAt" },
      { label: "Actions", field: "actions" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    timeOutSearchCustomer: null,
  }),
  watch: {
    searchQuery() {
      this.getUsers();
    },
  },
  computed: {
    users() {
      return this.$store.state.usersModule.users;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    typeUserOptions() {
      if(this.typeUserLoged == 'Root'){
        return [
          { text: "Super Admin", value: "superadmin" },
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      }else{
        return [
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      }
    },
  },
  methods: {
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch("customerModule/getCustomers", {
            q: search,
            paginate: {
                page: 1,
                perPage: 20,
                sortDesc: true 
              } 
          });
          this.customerOptions = res.data.customers;
          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    async dataCustomer() {
      try {
        const res = await this.$store.dispatch("customerModule/getCustomers")
        this.customerOptions = res.data.customers;
      } catch (error) {
        console.log(error)
      }
    },
    randomPass() {
      this.passwordFieldType = 'text'
      var length = 15,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.formData.password = retVal;
    },
    async addUser(){
      if (!await this.$refs.Form.validate()) {
        return
      }
      try {
        if(this.$store.getters["usersModule/typeUser"] == 'admin'){
          this.formData.customerId =  this.$store.getters["usersModule/userLoged"].customerId;
        }
        this.loadingData = true;
        await this.$store.dispatch('usersModule/addUserAdmin', this.formData)
        .then(() => {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: "User added successfully",
              icon: "CheckCircleIcon",
              variant: "success"
            },
          });
          this.getUsers()
          this.loadingData = false;
          this.openModal = false;
          this.formData = {
            customerId: "",
            username: "",
            password: "",
          };
        })
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add user admin",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }   
    },
    async getUsers(page = 1) {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("usersModule/getUsers", {
          q: this.searchQuery,
          paginate: {
            page: page,
            perPage: this.per_page,
            sortDesc: true 
          } 
      });

      this.total = res.data.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    showGames(user){
      this.$refs.games.user = JSON.parse(JSON.stringify(user) )
      this.$refs.games.modalShow = true
    },
    showProduct(user){
      this.$refs.products.user = JSON.parse(JSON.stringify(user) )
      this.$refs.products.modalShow = true
    },
    userChangePassword(user){
      this.$refs.userChangePassword.user = JSON.parse(JSON.stringify(user) )
      this.$refs.userChangePassword.modalShow = true
    }

  },
  mounted() {
    this.getUsers();
    this.dataCustomer();
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}
.form-group {
    margin-bottom: 0 !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

